import React from 'react'
import ApiHandler from '../model/ApiHandler';
import Status from '../components/Status';
import { Modal, Button, Alert } from 'react-bootstrap';



class ApplicationCard extends React.Component {
    constructor(props) {
        super();
        // let serverId = new URLSearchParams(props.location.search).get("serverId");
        let serverId = '';
        this.props = props;
        this.application = props.application;
        this.state = {
            error: "",
            success: "",
            created_at: props.application.created_at,
            id: props.application.id,
            name: props.application.name,
            domain: props.application.domain,
            server_id: props.application.server_id,
            status: props.application.status,
            username: props.application.username,
            password: props.application.password,
            db_name: props.application.db_name,
            db_username: props.application.db_username,
            db_password: props.application.db_password,
            ssl_enabled: props.application.ssl_enabled,
            server: (props.application.server) ? props.application.server : '',
            loading: false,
            newName: "",
            error: "",
            success: "",
            servers: [],
            dropdownOpen: false,
            isApplicationClicked: false,
            code: "",
            selectedServerFilter: (serverId) ? serverId : '',
            showUpdateModal: false,
            showCloneModal:false,
            serverName:props.application.server.name,
            selectedServerId: (serverId) ? serverId : '',
            selectedDomain: "",
            isWordpress: true,
        }
        this.apiHandler = new ApiHandler();
    }

    renameHandle = () => {
        if (this.state.loading) {
            return;
        }
        this.setState({ error: "", success: "", loading: true })
        this.apiHandler.renameApplication(this.state.id, this.state.newName, (message, data) => {
            this.setState({ error: "", success: message, loading: false, showRenameModal: false, name: this.state.newName});
            this.props.appsReload()
        }, (message) => {
            this.setState({ error: message, success: "", loading: false })
            console.log(message);
        });
    }

    showError(err) {
        this.setState({ error: err })
    }
    
    getServers = () => {
        this.apiHandler.getServers(this.state.serverPage, (msg, data) => {
            this.setState({ servers: data.data })
        }, err => {
            this.showError(err);
        })
    }

    componentDidMount() {
        this.getServers();
    }

    renderServers() {
        let servers = [];
        this.state.servers.forEach((data, index) => {
            if (data.status === "READY" && data.id == this.application.server_id) {
                servers.push(<option key={index} value={data.uuid}>{data.name}</option>);
            }
        })
        return servers;
    }

    handleCloneApplication = () => {
         
        let form = document.getElementsByTagName("form")[0]
        if (!form.checkValidity()) {
            form.reportValidity();
            return;
        }
        if (this.state.loadding) {
            return;
        }
        this.setState({ error: "", success: "", loadding: true })
        this.apiHandler.cloneApplication(this.state.selectedServerId, this.state.selectedDomain,this.application.id, this.state.isWordpress, (message, data) => {
            console.log("-- application reload")
            this.setState({ error: "", success: message, loadding: false, showCloneModal: false });
            this.props.appsReload();
        }, (message) => {
            this.setState({ error: message, success: "", loadding: false, showCloneModal: false });
            // console.log(message);
            this.props.appsReload();
        });
    }
   
    deleteHandle = () => {
        if (this.state.loading) {
            return;
        }
        this.setState({ error: "", success: "", loading: true })
        this.apiHandler.deleteApplication(this.state.id, (message, data) => {
            this.setState({ error: "", success: message, loading: false })
            this.props.appsReload()
        }, (message) => {
            this.setState({ error: message, success: "", loading: false })
            console.log(message);
        });
    }

    handleRenameModalShow = (e) => {
        e.preventDefault();
        this.setState({
            showRenameModal: true
        })
    }

    handleRenameModalClose= () => {
        this.setState({
            showRenameModal: false
        })
    }

    updateApplication = () => {
        if (this.state.loading) {
            return;
        }
        this.setState({ error: "", success: "", loading: true })
        this.apiHandler.updateApp(this.state.id, this.state.name, this.state.serverName, (message, data) => {
            this.setState({ error: "", success: message, loading: false })
            this.props.appsReload()
        }, (message) => {
            this.setState({ error: message, success: "", loading: false })
            console.log(message);
        });
    }

    handleModalClose = () => {
        this.setState({
            code: '',
            showModal: false,
        })
    }
    handleModalShow = () => {
        this.setState({
            showModal: true,
        })
    }
    handleCloneModalShow = () => {
        this.setState({
            showCloneModal: true,
        })
    }
    handleCloneModalClose = () => {
        this.setState({
            showCloneModal: false,
        })
    }

    handleUpdateModalShow = () => {
        this.setState({
            showUpdateModal: true,
        })
    }
    handleUpdateModalClose = () => {
        this.setState({
            showUpdateModal: false,
        })
    }
    dataChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    setShow() {
        this.setState({ error: "", success: "", })
    }

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.handleCloneApplication();
        }
    }
    render() {
        return (
            <>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="card hover-dark-background"
                        onClick={(e) => {
                            if(e.target.tagName!="I"){
                               this.props.applicationClickHandler(this.props.application)
                            }
                        }}
                    >
                        <div className="card-header cursor-pointer">
                            <div className="row no-gutters">
                                <div className="col-2 p-1 align-self-center pl-0">
                                    <img alt="wordpress" style={{ width: "100%" }} src={require("../assets/images/wordpress.png")} />
                                </div>
                                <div className="col-8 p-2 align-self-center">
                                    <h6 className="heading"><span onClick={() => this.props.applicationClickHandler(this.props.application)}>{this.state.name}</span>&nbsp;<i style={{ fontSize: '16px'}} className="nav-icon fa fa-edit" onClick={this.handleRenameModalShow}></i></h6>
                                    <p className="sub-heading">
                                        Created: {new Date(this.state.created_at).toDateString()}
                                    </p>
                                </div>
                                <div className="col-2 align-self-center text-right">
                                    <Status status={this.state.status} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body server-details-list">
                            <div className="row">
                                <div className="col-2">
                                    <img src={require("../assets/images/icons/server-ip.svg")} alt="" srcSet="" />
                                </div>
                                <div className="col-8"><p>{this.state.domain}</p></div>
                                <div className="col-2 p-0">
                                    <button className="btn btn-danger btn-sm" style={{marginRight:"0.2em"}} onClick={this.handleModalShow}>
                                        {this.state.loading ?
                                            <svg className="loading" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.537 17.567C14.7224 19.1393 12.401 20.0033 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 12.136 19.33 14.116 18.19 15.74L15 10H18C17.9998 8.15621 17.3628 6.36906 16.1967 4.94089C15.0305 3.51272 13.4069 2.53119 11.6003 2.16236C9.79381 1.79352 7.91533 2.06002 6.28268 2.91677C4.65002 3.77351 3.36342 5.16791 2.64052 6.86408C1.91762 8.56025 1.80281 10.4541 2.31549 12.2251C2.82818 13.9962 3.93689 15.5358 5.45408 16.5836C6.97127 17.6313 8.80379 18.1228 10.6416 17.9749C12.4795 17.827 14.2099 17.0488 15.54 15.772L16.537 17.567Z" fill="white" />
                                            </svg>
                                            :
                                            <i className="fa fa-trash"></i>
                                        }
                                    </button>
                                    <button className="btn btn-success btn-sm" onClick={this.handleCloneModalShow}>
                                        {this.state.loading ?
                                            <svg className="loading" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.537 17.567C14.7224 19.1393 12.401 20.0033 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 12.136 19.33 14.116 18.19 15.74L15 10H18C17.9998 8.15621 17.3628 6.36906 16.1967 4.94089C15.0305 3.51272 13.4069 2.53119 11.6003 2.16236C9.79381 1.79352 7.91533 2.06002 6.28268 2.91677C4.65002 3.77351 3.36342 5.16791 2.64052 6.86408C1.91762 8.56025 1.80281 10.4541 2.31549 12.2251C2.82818 13.9962 3.93689 15.5358 5.45408 16.5836C6.97127 17.6313 8.80379 18.1228 10.6416 17.9749C12.4795 17.827 14.2099 17.0488 15.54 15.772L16.537 17.567Z" fill="white" />
                                            </svg>
                                            :
                                            <i className="fa fa-copy"></i>
                                        }
                                    </button>
                                </div>
                            </div>
                           
                            <div className="row" style={{marginTop:"0.5em"}}>
                                <div className="col-2">
                                    <img src={require("../assets/images/icons/server-ram.svg")} alt="" srcSet="" />
                                </div>
                                <div className="col-8"><p>{this.state.server.name}</p></div>
                                <div className="col-2 p-0">
                                    <button className="btn btn-primary btn-sm" onClick={this.handleUpdateModalShow}>
                                        {this.state.loading ?
                                            <svg className="loading" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.537 17.567C14.7224 19.1393 12.401 20.0033 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 12.136 19.33 14.116 18.19 15.74L15 10H18C17.9998 8.15621 17.3628 6.36906 16.1967 4.94089C15.0305 3.51272 13.4069 2.53119 11.6003 2.16236C9.79381 1.79352 7.91533 2.06002 6.28268 2.91677C4.65002 3.77351 3.36342 5.16791 2.64052 6.86408C1.91762 8.56025 1.80281 10.4541 2.31549 12.2251C2.82818 13.9962 3.93689 15.5358 5.45408 16.5836C6.97127 17.6313 8.80379 18.1228 10.6416 17.9749C12.4795 17.827 14.2099 17.0488 15.54 15.772L16.537 17.567Z" fill="white" />
                                            </svg>
                                            :
                                             <i className="fa fa-edit"></i>
                                        }
                                    </button>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <a href={((this.state.ssl_enabled === "1") ? "https://" : "http://") + this.state.domain} target="_blank" type="button" rel="noopener noreferrer" className="btn btn-theme btn-sm">
                                    Visit Application
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal centered show={this.state.showModal} onHide={this.handleModalClose}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title><span className="text-danger">Delete {this.application.name}?</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert onClose={() => this.setShow()} show={(this.state.error !== "") ? true : false} variant="danger" dismissible>
                                {this.state.error}
                            </Alert>
                            <Alert onClose={() => this.setShow()} show={(this.state.success !== "") ? true : false} variant="success" dismissible>
                                {this.state.success}
                            </Alert>
                            <p className="m-0 p-0 text-muted">Are you sure, you want to delete this application?
                           All files will be deleted and it can't be restored.</p>
                            <div className="modal-form mt-4">
                                <label htmlFor="code">Enter Application name.</label>
                                <div className="input-group">
                                    <input placeholder={this.application.name} onChange={this.dataChange} onKeyDown={this.onEnterPress} required type="text" className="form-control form-input-field" name="code" value={this.state.code} id="code" />
                                </div>
                            </div>
                            <p></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" onClick={this.handleModalClose}>
                                GO BACK
                                      </Button>
                            <Button disabled={(this.state.code === this.application.name) ? false : true} className="btn btn-theme" onClick={this.deleteHandle}>
                                {
                                    this.state.loading ?
                                        <img alt="" src={require("../assets/images/loading.gif")} style={{ width: "25px", filter: "brightness(20)" }} />
                                        : "Yes, Delete"
                                }
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
                <Modal centered show={this.state.showRenameModal} onHide={this.handleRenameModalClose}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title><span className="text-danger">Rename '{this.application.name}'?</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                        <div className="input-group">
                                    <input type="text" onChange={this.dataChange} className="form-control form-input-field" name="newName"  id="newName" value={this.state.newName} onKeyDown={(event) => { if(event.key === 'Enter'){ this.renameHandle() } }}/>
                                </div>
                           
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" onClick={this.handleRenameModalClose} style={{'display': this.state.buttons}}>Close</Button>
                            <Button className="btn btn-theme" style={{'display': this.state.buttons}} onClick={this.renameHandle}>Rename</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
                <Modal centered show={this.state.showUpdateModal} onHide={this.handleUpdateModalClose}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title><span className="text-danger">Update Application</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Alert onClose={() => this.setShow()} show={(this.state.error !== "") ? true : false} variant="danger" dismissible>
                                {this.state.error}
                            </Alert>
                            <Alert onClose={() => this.setShow()} show={(this.state.success !== "") ? true : false} variant="success" dismissible>
                                {this.state.success}
                            </Alert>
                            <div className="modal-form mt-4">
                                <label htmlFor="code">Update Application Name.</label>
                                <div className="input-group">
                                    <input placeholder={this.application.name} onChange={this.dataChange} onKeyDown={this.onEnterPress} required type="text" className="form-control form-input-field" name="name" value={this.state.name} id="code" />
                                </div>
                            </div>
                            <div className="modal-form mt-4">
                                <label htmlFor="code">Update Server Name.</label>
                                <div className="input-group">
                                    <input placeholder={this.application.server.name} onChange={this.dataChange} onKeyDown={this.onEnterPress} required type="text" className="form-control form-input-field" name="serverName" value={this.state.serverName} id="code" />
                                </div>
                            </div>
                            <p></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" onClick={this.handleUpdateModalClose}>
                                GO BACK
                                      </Button>
                            <Button className="btn btn-theme" onClick={this.updateApplication}>
                                {
                                    this.state.loading ?
                                        <img alt="" src={require("../assets/images/loading.gif")} style={{ width: "25px", filter: "brightness(20)" }} />
                                        : "Yes, Update"
                                }
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
                <Modal centered show={this.state.showCloneModal} onHide={this.handleCloneModalClose}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>CLONE APPLICATION</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert onClose={() => this.setShow()} show={(this.state.error !== "") ? true : false} variant="danger" dismissible>
                                {this.state.error}
                            </Alert>
                            <Alert onClose={() => this.setShow()} show={(this.state.success !== "") ? true : false} variant="success" dismissible>
                                {this.state.success}
                            </Alert>
                            <div className="modal-form">
                                <label htmlFor="selectedDomain">Enter Domain Name</label>
                                <div className="input-group">
                                    <input onKeyDown={this.onEnterPress} required type="text" className="form-control form-input-field" name="selectedDomain" value={this.state.selectedDomain} onChange={this.dataChange} id="selectedDomain" />
                                    <div className="input-group-append">
                                        <svg width="38" height="38" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="45" height="45" rx="8" fill="#7973FE" />
                                            <path d="M23 33C17.477 33 13 28.523 13 23C13 17.477 17.477 13 23 13C28.523 13 33 17.477 33 23C33 28.523 28.523 33 23 33ZM20.71 30.667C19.7234 28.5743 19.1519 26.3102 19.027 24H15.062C15.2566 25.5389 15.8939 26.9882 16.8966 28.1717C17.8992 29.3552 19.224 30.2221 20.71 30.667ZM21.03 24C21.181 26.439 21.878 28.73 23 30.752C24.1523 28.6766 24.8254 26.3695 24.97 24H21.03ZM30.938 24H26.973C26.8481 26.3102 26.2766 28.5743 25.29 30.667C26.776 30.2221 28.1008 29.3552 29.1034 28.1717C30.1061 26.9882 30.7434 25.5389 30.938 24ZM15.062 22H19.027C19.1519 19.6898 19.7234 17.4257 20.71 15.333C19.224 15.7779 17.8992 16.6448 16.8966 17.8283C15.8939 19.0118 15.2566 20.4611 15.062 22ZM21.031 22H24.969C24.8248 19.6306 24.152 17.3235 23 15.248C21.8477 17.3234 21.1746 19.6305 21.03 22H21.031ZM25.29 15.333C26.2766 17.4257 26.8481 19.6898 26.973 22H30.938C30.7434 20.4611 30.1061 19.0118 29.1034 17.8283C28.1008 16.6448 26.776 15.7779 25.29 15.333Z" fill="white" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-form">
                                <label htmlFor="select_server">Select server in which you want to Clone application</label>
                                <div className="input-group">
                                    <select required className="custom-select" name="selectedServerId" value={this.state.selectedServerId} onChange={this.dataChange} id="select_server">
                                        <option value="">Select</option>
                                        {
                                            this.renderServers()
                                        }
                                    </select>
                                    <div className="input-group-append">
                                        <svg width="38" height="38" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="45" height="45" rx="8" fill="#7973FE" />
                                            <path d="M15 22H29V16H15V22ZM31 15V31C31 31.2652 30.8946 31.5196 30.7071 31.7071C30.5196 31.8946 30.2652 32 30 32H14C13.7348 32 13.4804 31.8946 13.2929 31.7071C13.1054 31.5196 13 31.2652 13 31V15C13 14.7348 13.1054 14.4804 13.2929 14.2929C13.4804 14.1054 13.7348 14 14 14H30C30.2652 14 30.5196 14.1054 30.7071 14.2929C30.8946 14.4804 31 14.7348 31 15ZM29 24H15V30H29V24ZM17 26H20V28H17V26ZM17 18H20V20H17V18Z" fill="white" />
                                        </svg>

                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "none !important" }} className="hide">
                                <label htmlFor="isWordpress">Wordpress</label>
                                <input className="form-control" type="checkbox" name="isWordpress" checked={this.state.isWordpress} onChange={this.dataChange} style={{ width: "20px", height: "20px" }} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" onClick={this.handleCloneModalClose}>
                                CLOSE
                            </Button>
                            <Button className="btn btn-theme" onClick={this.handleCloneApplication}>
                                {
                                    this.state.loadding ?
                                        <img alt="" src={require("../assets/images/loading.gif")} style={{ width: "25px", filter: "brightness(20)" }} />
                                        : "CLONE APPLICATION"
                                }
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        )
    }
}
export default ApplicationCard;
