import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router';
import { bake_cookie } from 'sfcookies';
import ApiHandler from "../model/ApiHandler";


const VerifyRegistration = () => {
    const { token } = useParams();
    const [isVerified, setIsVerified] = useState(false);
    const [error, setError] = useState(null);
    const apiHandler = new ApiHandler();

    useEffect(() => {
        apiHandler.verify(token, (response) => {
            bake_cookie("name", response.name);
            bake_cookie("email", response.email);
            bake_cookie("auth", response.access_tokens.pop());

            setIsVerified(true);
        }, (errorMessage) => {
            setError(errorMessage);
        });
    }, [token, apiHandler]);

    if (isVerified) {
        window.location.href = "/projects"; // Redirection vers le tableau de bord
        return null; // Éviter le rendu supplémentaire
    }

    return (
        <div>
            {error ? <p>{error}</p> : <p>Verifying...</p>}
        </div>
    );
};

export default VerifyRegistration;
